<!-- -->
<template>
  <div class="home">
    <van-image
      class="et_hygqyw"
      fit="cover"
      src="https://scqc-obs.obs.cn-south-1.myhuaweicloud.com/cutimg/youhuijuan_genduoyouhuiquandengnill.png"
    >
    </van-image>
    <wx-open-launch-weapp
      id="launch-btn"
      username="gh_60d3d1399dcd"
      :path="path"
      style="
        width: 304px;
        height: 44px;
        position: absolute;
        bottom: 20vh;
        z-index: 9;
      "
    >
      <script type="text/wxtag-template">
        <style>
            .ant-btn {
                line-height: 1.499;
                position: relative;
                display: inline-block;
                font-weight: 400;
                white-space: nowrap;
                text-align: center;
                background-image: none;
                border: 1px solid #d9d9d9;
                -webkit-box-shadow: 0 2px 0 rgba(0,0,0,0.015);
                box-shadow: 0 2px 0 rgba(0,0,0,0.015);
                cursor: pointer;
                -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
                transition: all .3s cubic-bezier(.645, .045, .355, 1);
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                height: 32px;
                padding: 0 15px;
                font-size: 14px;
                border-radius: 4px;
                color: rgba(0,0,0,0.65);
                background-color: #fff;
            }
            .ant-btn-red {
                color: #fff;
                background-color: #FF5A44;
                border-color: #FF5A44;
                text-shadow: 0 -1px 0 rgba(0,0,0,0.12);
                -webkit-box-shadow: 0 2px 0 rgba(0,0,0,0.045);
                box-shadow: 0 2px 0 rgba(0,0,0,0.045);
                width: 304px;
                height: 44px;
                background: linear-gradient(180deg, #ffc926 0%, #fc702e 100%);
                box-shadow: 0px 3px 12px #f4551a;
                border-radius: 20px;
            }
        </style>
        <button class="ant-btn ant-btn-red">进入小程序</button>
      </script>
    </wx-open-launch-weapp>

  </div>
</template>

<script>
export default {
  inject: ["reload"],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      tel: "",
      show: true,
      number: "",
      // baseUrl: "http://192.168.0.150:8000",
      baseUrl: "https://app.cheshiji.com.cn",
      count: 60,
      wxHtml: "",
      isShowIn: false,
      inviteCode: this.GetRequest("inviteCode"), //邀请码
      source: this.$route.query.source, //来源
      objId: this.$route.query.objId ? this.$route.query.objId : "",
      // datb: "",
       path: `pages/homePage/index.html?activityPromotionUserId=${this.GetRequest(
        "activityPromotionUserId"
      )}&userType=2
`,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  created() {
    const oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    oScript.onload = this.getShopWxConfig;
    document.body.appendChild(oScript);
  },
  //方法集合
  methods: {
    objIdFun() {
      if (this.$route.query.objId) {
        return this.$route.query.objId;
      } else {
        return "";
      }
    },
    GetRequest(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        r[2] = r[2].replace(/%20/g, "+");
        return decodeURIComponent(r[2]);
      } else {
        return null;
      }
    },
    getShopWxConfig() {
      this.$http
        .post(`${this.baseUrl}/admin/base/config/weixin/share/inject_js`, {
          url: window.location.href,
        })
        .then((res) => {
          if (res.data.code == 200) {
            let e = JSON.parse(res.data.data);
            wx.config({
              debug: false,
              appId: e.appId,
              nonceStr: e.nonceStr,
              timestamp: e.timestamp,
              signature: e.signature,
              openTagList: ["wx-open-launch-weapp"],
              jsApiList: ["chooseImage", "previewImage"],
            });
            wx.ready(function (res) {
              console.log("res44444444", res);
            });
            wx.error(function (err) {
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              console.log("err44444444", err);
            });
          }
        });
    },

    StorageFun() {
      let a = sessionStorage.getItem("invite_users");
      if (!a) {
        a = 1;
      }
      if (a < 2) {
        sessionStorage.setItem("invite_users", Number(a) + 1);
        setTimeout((e) => {
          // console.log(a)
          //  this.reload()
          window.location.reload();
        }, 200);
      } else {
        sessionStorage.setItem("invite_users", "");
      }
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.StorageFun()
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  width: 100%;
}
.et_hygqyw {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}
.ant-btn-red {
  color: #fff;
  background-color: #ff5a44;
  border-color: #ff5a44;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  width: 304px;
  height: 44px;
  background: linear-gradient(180deg, #ffc926 0%, #fc702e 100%);
  box-shadow: 0px 3px 12px #f4551a;
  border-radius: 20px;
  position: absolute;
  bottom: 20vh;
  z-index: 9;
}
.weg_wg {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100vh;
  align-content: center;
  width: 100%;
  margin-top: 47vh;
}
.btn {
  width: 100px;
  height: 100px;
  background: chartreuse;
}
.wd {
  width: 100%;
  height: 100vh;
}
.wf_f {
  width: 328px;
  height: 42px;
  margin-top: 24px;
  position: relative;
}
.wf_wgg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.van-cell {
  border-radius: 24px;
  border: 1px solid #d5d5d5;
}
.code-btn {
  position: absolute;
  top: 0;
  right: 16px;
  line-height: 45px;
}
.wef_huoqu {
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  line-height: 17px;
  color: #ff8f0e;
  border-left: 1px solid #d5d5d5;
  padding-left: 20px;
}
.liji {
  width: 328px;
  height: 46px;
  background: #ff8f0e;
  opacity: 1;
  border-radius: 24px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  line-height: 46px;
  margin-top: 30px;
  color: #ffffff;
  text-align: center;
}
.wef_whh {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100vh;
  margin-top: 9vh;
}
</style>